body[data-theme="light"] {
    @import '../light-slider.scss';
 
    .slider-tab {
       background-color: #D7CAAA;
    }

    #skills {
       background: #ffffff;
       min-height: 200px;
       width: 100%;
       overflow: hidden;
       padding-bottom: 10%;
    }

    #skills .text-white {
        color: #000000 !important;
    }
}